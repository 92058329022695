import { useClearRefinements } from 'react-instantsearch-core'
import { useTranslation } from 'next-i18next'

import type { QaHook } from '@knauf-group/ct-designs/utils/types'
import type { Theme } from '@mui/material'
import { Button, useMediaQuery } from '@mui/material'
import { useAnalyticsTrackResetFilters } from '@/hooks/analytics/useAnalyticsTracking'
import { useSearchBoxWithDebounce } from '@/hooks/useSearchBoxWithDebounce'

import { useRenderKey } from '@/contexts/RenderKeyProvider'
import { useSearchContext } from '@/contexts/SearchContext/SearchContext'

export const FilterChipsResetButton = ({ dataCy }: QaHook) => {
  const { trackResetFiltersEvent } = useAnalyticsTrackResetFilters({})

  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.filtersPanel.buttons',
  })
  const isScreenLgDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down('lg'))
  const { internalQuery, setInternalQuery } = useSearchContext()

  const { refine, canRefine } = useClearRefinements()
  const { refine: refineSearch } = useSearchBoxWithDebounce()

  const { forceNewRenderForFilterChips } = useRenderKey()
  const handleOnClick = () => {
    trackResetFiltersEvent(isScreenLgDown ? t('resetAll') : t('resetAllFilters'))
    refine()
    forceNewRenderForFilterChips()
    setInternalQuery('')
    refineSearch('')
  }

  return (
    <Button
      variant="outlined"
      onClick={handleOnClick}
      disabled={!canRefine && internalQuery === ''}
      data-cy={dataCy}
    >
      {t('resetAll')}
    </Button>
  )
}
